import React from 'react'

import GatsbyHead from '@components/Layout/GatsbyHead'
import Layout from '@components/Layout/Layout'
import SEO from '@components/Layout/SEO'

const Calculator = () => {
	const [scriptLoaded, setScriptLoaded] = React.useState(false)

	React.useEffect(() => {
		if (!scriptLoaded) {
			const script = document.createElement('script')

			script.textContent = `
				document.addEventListener("DOMContentLoaded", function() {
					let section = document.querySelector('.Section-module--section--b91fb');

					if (section) {
						section.style.padding = "0";
					}

					let title = document.querySelector('.DefaultPage-module--title--f38fc');

					if (title) {
						title.style.display = "none"; 
						title.style.lineHeight = "0";
						title.style.visibility = "hidden";
					}

					let wysiwyg = document.querySelector('.Wysiwyg-module--wysiwyg--6adcb');

					if (wysiwyg) {
						wysiwyg.style.display = "flex";
						wysiwyg.style.alignItems = "center";
						wysiwyg.style.alignContent = "center";
					}
				});

				document.documentElement.style.scrollBehavior = "smooth";
			`
			document.head.appendChild(script)

			setScriptLoaded(true)
		}
	}, [scriptLoaded])

	return (
		<Layout type="full">
			<div
				id="iframe-container"
				style={{
					height: '2000px',
					width: '100%',
					alignContent: 'center',
					alignItems: 'center',
					justifyContent: 'center',
					alignSelf: 'center',
					maxWidth: '95vw',
				}}
			>
				<iframe
					src="https://ffiframe.motorfinity.ai"
					scrolling="yes"
					style={{ width: '100%', height: '2000px', border: 'none', overflow: 'hidden' }}
				>
					Iframes not supported
				</iframe>
			</div>
		</Layout>
	)
}

export default Calculator

export function Head() {
	return (
		<>
			<GatsbyHead />
			<SEO data={{ title: 'Apply' }} />
		</>
	)
}
